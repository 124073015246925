import { render, staticRenderFns } from "./CordlessVacuumCleanerFilters.vue?vue&type=template&id=f1fc1232&scoped=true&"
import script from "./CordlessVacuumCleanerFilters.vue?vue&type=script&lang=js&"
export * from "./CordlessVacuumCleanerFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1fc1232",
  null
  
)

export default component.exports